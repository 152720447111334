/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {GetStep1} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import parse from "date-fns/parse";
import axios from 'axios'
import LoadingAnimation from '../../../LoadingAnimation'
import dayjs from 'dayjs'
import {Buffer} from 'buffer';

const animatedComponents = makeAnimated();
const optionfa = [
    { value: 'ComCare', label: 'ComCare' },
    { value: 'MOE', label: 'MOE' },
    { value: 'Religious Organisations (i.e. Mosque, Temple, Church etc)', label: 'Religious Organisations (i.e. Mosque, Temple, Church etc)' },
    { value: 'Self Help Group (CDAC, Mendaki, SINDA, EA)', label: 'Self Help Group (CDAC, Mendaki, SINDA, EA)' },
    { value: 'Not applicable', label: 'Not applicable' },
    { value: 'Other', label: 'Other' },
  ]
  const optionprog = [
    { value: 'Gift of Reading (Kindergarten 1 - 2)', label: 'Gift of Reading (Kindergarten 1 - 2)' },
    { value: 'Gift of Expression (Primary 1 - 2)', label: 'Gift of Expression (Primary 1 - 2)' },
    { value: 'Academic Coaching (Secondary 1- Secondary 5)', label: 'Academic Coaching (Secondary 1- Secondary 5)' },
    { value: 'Art Lab (Primary 1 - 3)', label: 'Art Lab (Primary 1 - 3)' },
    { value: 'Art Lab (Primary 4 - 6)', label: 'Art Lab (Primary 4 - 6)' },
    { value: 'Art Lab (Secondary 1- 5)', label: 'Art Lab (Secondary 1- 5)' },
    { value: 'Dance Lab (Primary 3 - 6)', label: 'Dance Lab (Primary 3 - 6)' },
    { value: 'Tasek Sailors East - Football (7 - 12 years old)', label: 'Tasek Sailors East - Football (7 - 12 years old)' },
    { value: 'Tasek Sailors East - Football (13 - 21 years old)', label: 'Tasek Sailors East - Football (13 - 21 years old)' },
    { value: 'Tasek Sailors West - Football (7 - 12 years old)', label: 'Tasek Sailors West - Football (7 - 12 years old)' },
    { value: 'Tasek Sailors West - Football (13 - 21 years old)', label: 'Tasek Sailors West - Football (13 - 21 years old)' },
    { value: 'Tuition (Primary 3 - 6)', label: 'Tuition (Primary 3 - 6)' },
    { value: 'Tuition (Secondary 1- 5)', label: 'Tuition (Secondary 1- 5)' },
  ]
export function RegStep1() {
  //const [xdata, setXdata] = useState<any>();
  const [showCitizenText, setShowCitizenText] = useState(false)
  const [citizenText, setCitizenText] = useState("")
  const [showRaceText, setShowRaceText] = useState(false)
  const [isRetrieved, setIsRetrieved] = useState<boolean>()
  const [raceText, setRaceText] = useState("")
  const [showHousingText, setShowHousingText] = useState(false)
  const [housingText, setHousingText] = useState("")
  const [showFaText, setShowFaText] = useState(false)
  const [faText, setFaText] = useState("")
  const [loading, setLoading] = useState<boolean>()
  const {saveAuth, setCurrentUser} = useAuth()
  const [modalShow, setModalShow] = useState(false);
  const [tasekProgSelect, setTasekProgSelect] = useState("")
  const [tasekFaSelect, setTasekFaSelect] = useState("")
  const [faList, setFaList] = useState<any[]>([]);
  const [progList, setProgList] = useState<any[]>([]);
  const [masterProgList, setMasterProgList] = useState<any[]>([]);
  const Swal = require('sweetalert2')

  const NoCompleted = () => {
    return (
      <div className='mb-lg-15 alert alert-danger mb-3'>
      <div className='alert-text font-weight-bold'>Your previous enrolment data are empty!</div>
    </div>
    )
  }
  const Completed = () => {
    return (
      <div className='mb-lg-15 alert alert-success mb-3'>
      <div className='alert-text font-weight-bold'>Your previous enrolment data are loaded!</div>
    </div>
    )
  }
  let xdataRef = useRef<any>()
  var initialValues = {
    fullname: "",
    nric: '',
    gender: '',
    residential: '',
    dob: dayjs(new Date()).format('YYYY-MM-DD'),
    race: '',
    mobilenumber: "",
    address: '',
    postalcode: '',
    income: '',
    household: '',
    housingtype: '',
    isrentalflat:'',
    //fa: '',
    medcons: '',
    learningneeds: '',
    edulevel: '',
    //progenrolled: '',
    options1: '',
    options2: '',
    options3: '',
    options4: '',
    options5: '',
  }
    const fetchData= async () => {
      setLoading(true);
  
      const {data: xcontent} = await GetStep1(localStorage.getItem("regToken"));
  
      //console.log(xcontent)
      //setXdata(xcontent);
      xdataRef.current=xcontent;
      setFaList(xdataRef.current.falist)
      setProgList(xdataRef.current.proglist)
      setMasterProgList(xdataRef.current.dataproglist)
      setTasekFaSelect(xdataRef.current.fa)
      setTasekProgSelect(xdataRef.current.progenrolled)
      if(xdataRef.current != null){
        if(String(xdataRef.current.residential).includes("Other")){
          setCitizenText(String(xdataRef.current.residential).split("|")[1])
          setShowCitizenText(true)
        }
        if(String(xdataRef.current.race).includes("Other")){
          setRaceText(String(xdataRef.current.race).split("|")[1])
          setShowRaceText(true)
        }
        if(String(xdataRef.current.housingtype).includes("Other")){
          setHousingText(String(xdataRef.current.housingtype).split("|")[1])
          setShowHousingText(true)
        }
        if(String(xdataRef.current.fa).includes("Other")){
          setFaText(xdataRef.current.fatext)
          setShowFaText(true)
          console.log(faList)
        }
      }
      setIsRetrieved(xdataRef.current.nric ? true : false)
      if(!xdataRef.current.nric){
        localStorage.clear()
      }
      console.log(xdataRef.current.nric ? "true" : "false")
      setLoading(false);
    };
    useEffect(() => {
      fetchData()
      //console.log(!loading && xdataRef.current.nric !== null ? xdataRef.current.nric : "kosong")
    }, []);
    //console.log(masterProgList)
    if(xdataRef.current != null){
      //setFaList(xdataRef.current.falist)
      //setProgList(xdataRef.current.proglist)
      var residentval= String(xdataRef.current.residential).includes("Other") ? "Other" : xdataRef.current.residential
      var raceval= String(xdataRef.current.race).includes("Other") ? "Other" : xdataRef.current.race
      var housingval= String(xdataRef.current.housingtype).includes("Other") ? "Other" : xdataRef.current.housingtype
      //var faval= String(xdataRef.current.fa).includes("Other") ? "Other" : xdataRef.current.fa
      initialValues = {
        fullname: xdataRef.current.fullname,
        nric: xdataRef.current.nric,
        gender: xdataRef.current.gender,
        residential: residentval,
        dob: dayjs(xdataRef.current.dob).format('YYYY-MM-DD'),
        race: raceval,
        mobilenumber: xdataRef.current.mobilenumber,
        address: xdataRef.current.address,
        postalcode: xdataRef.current.postalcode,
        income: xdataRef.current.income,
        household: xdataRef.current.household,
        housingtype: housingval,
        isrentalflat:xdataRef.current.isrentalflat,
        //fa: xdataRef.current.fa,
        medcons: xdataRef.current.medcons,
        learningneeds: xdataRef.current.learningneeds,
        edulevel: xdataRef.current.edulevel,
        //progenrolled: xdataRef.current.progenrolled,
        options1: xdataRef.current.options1,
        options2: xdataRef.current.options2,
        options3: xdataRef.current.options3,
        options4: xdataRef.current.options4,
        options5: xdataRef.current.options5,
      }
    }
    
    //console.log(initialValues)
    const registrationSchema = Yup.object().shape({
      fullname: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Fullname is required'),
      nric: Yup.string()
      .min(4, 'Minimum 4 symbols')
      .max(4, 'Maximum 4 symbols')
      .required('NRIC is required'),
      gender: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .required('Gender is required'),
      residential: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Residential status is required'),
      dob: Yup.date()
        .transform(function (value, originalValue) {
          if (this.isType(value)) {
            return value;
          }
          const result = parse(originalValue, "dd.MM.yyyy", new Date());
          return result;
        })
        .typeError("please enter a valid date")
        .required()
        .min("1969-11-13", "Date is too early"),
      race: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .required('Race is required'),
      mobilenumber: Yup.string()
      .min(6, 'Minimum 6 symbols')
      .max(6, 'Maximum 6 symbols')
      .required('Mobile Number is required'),
      address: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Address is required'),
      postalcode: Yup.string()
      .min(6, 'Minimum 6 symbols')
      .max(6, 'MaxSimum 6 symbols')
      .required('Postal code is required'),
      income: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Total household income is required'),
      household: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('No. of Household Members is required'),
      housingtype: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .required('Type of Housing is required'),
      isrentalflat: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('You live in a rental flat is required'),
      //fa: Yup.string().required("Please select an Financial Assistance"),
      medcons: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .required('Medical Conditions is required'),
      learningneeds: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Learning Needs is required'),
      edulevel: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Current Education Level is required'),
      //progenrolled: Yup.string().required("Please select an Programme Enrolled"),
      options1: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('This input form is required'),
      options2: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('This input form is required'),
      options3: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .required('This input form is required'),
      options4: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('This input form is required'),
      options5: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('This input form is required'),
    })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      //alert(tasekProgSelect)
      //alert(values.race)

      if(tasekFaSelect == ""){
        Swal.fire({
          title: "Error!",
          text: "Please select Financial Assistance!",
          icon: "error",
          timer: 3000,
          showConfirmButton: false
        })  
        setSubmitting(false)
        setLoading(false)
        return
      }
      if(tasekProgSelect == ""){
        Swal.fire({
          title: "Error!",
          text: "Please select the programme you want enroll!",
          icon: "error",
          timer: 3000,
          showConfirmButton: false
        })  
        setSubmitting(false)
        setLoading(false)
        return
      }
      if(showCitizenText){
        if(citizenText == ""){
          Swal.fire({
            title: "Error!",
            text: "Citizen other is mandatory!",
            icon: "error",
            timer: 3000,
            showConfirmButton: false
          })  
          setSubmitting(false)
          setLoading(false)
          return
        }
      }
      if(showRaceText){
        if(raceText == ""){
          Swal.fire({
            title: "Error!",
            text: "Race other is mandatory!",
            icon: "error",
            timer: 3000,
            showConfirmButton: false
          })  
          setSubmitting(false)
          setLoading(false)
          return
        }
      }
      if(showFaText){
        if(faText == ""){
          Swal.fire({
            title: "Error!",
            text: "Finantial Assistance other is mandatory!",
            icon: "error",
            timer: 3000,
            showConfirmButton: false
          })  
          setSubmitting(false)
          setLoading(false)
          return
        }
      }
      if(showHousingText){
        if(housingText == ""){
          Swal.fire({
            title: "Error!",
            text: "Type of Housing other is mandatory!",
            icon: "error",
            timer: 3000,
            showConfirmButton: false
          })  
          setSubmitting(false)
          setLoading(false)
          return
        }
      }
      try {
        let formData=new FormData();
        formData.append("fullname", values.fullname);
        formData.append("nric",values.nric);
        formData.append("gender",values.gender);
        formData.append("residential",values.residential == "Other" ? "Other|" + citizenText : values.residential);
        formData.append("dob",values.dob);
        formData.append("race",values.race == "Other" ? "Other|" + raceText : values.race);
        formData.append("mobilenumber",values.mobilenumber);
        formData.append("address",values.address);
        formData.append("postalcode",values.postalcode);
        formData.append("income",values.income);
        formData.append("household",values.household);
        formData.append("housingtype",values.housingtype == "Other" ? "Other|" + housingText : values.housingtype);
        formData.append("isrentalflat",values.isrentalflat);
        formData.append("fa",tasekFaSelect.includes("Other") ? tasekFaSelect.split("|")[0] + "|" + faText.replace("|","") : tasekFaSelect);
        formData.append("medcons",values.medcons);
        formData.append("learningneeds",values.learningneeds);
        formData.append("edulevel",values.edulevel);
        formData.append("progenrolled",tasekProgSelect);
        formData.append("options1",values.options1);
        formData.append("options2",values.options2);
        formData.append("options3",values.options3);
        formData.append("options4",values.options4);
        formData.append("options5",values.options5);
        let xtoken=""
        if (localStorage.getItem("regToken") === null) {
          xtoken=Buffer.from(values.fullname!.toLowerCase() + "-" + values.mobilenumber!).toString("base64");
          localStorage.setItem("regToken",xtoken)
        }else{
          xtoken=String(localStorage.getItem("regToken"))
        }
        formData.append("token",xtoken);
        await axios.post(process.env.REACT_APP_API_URL + `/SaveStep1`, formData, {})
        .then(function (res) { // then print response status
            console.warn(res.data);
            if(res.data.status == "success"){
              window.location.href = "/auth/step2"
              setLoading(false)
              setSubmitting(false)
            }else{
              Swal.fire({
                title: "Error!",
                text: "Your data is failed to save",
                icon: "error",
                timer: 3000,
                showConfirmButton: false
              })     
              setLoading(false)
              setSubmitting(false)
            }
        })
      } catch (error) {
        console.error(error)
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          timer: 2000,
          showConfirmButton: false
        })
      }
      //window.location.href = "/auth/step2"
    },
  })
  const handleFaSelect = (e:any) => {
    //alert(e[0].value)
    //let xfalist: any[] = faList.push(e.value);
    setFaList(e);
    let fas=e?.map((item:any) => item.value).join(';');
    if(fas.includes("Other")){
      setShowFaText(true)
    }else{
      setShowFaText(false)
    }
    setTasekFaSelect(fas);
  };
  const handleChangeFaText = (e:any)=>{
    setFaText(e.target.value)
  }
  const handleProgSelect = (e:any) => {
    //alert(e.value)
    setProgList(e)
    let progs=e?.map((item:any) => item.value).join(';');
    setTasekProgSelect(progs);
  };
  const handleChangeCitizenOther = (e:any)=>{
    formik.setFieldValue('residential', e.target.value)
    if(e.target.value == "Other"){
      setShowCitizenText(true)
    }else{
      setShowCitizenText(false)
    }
  }
  const handleChangeCitizenText = (e:any)=>{
    setCitizenText(e.target.value)
  }
  const handleChangeRaceOther = (e:any)=>{
    formik.setFieldValue('race', e.target.value)
    if(e.target.value == "Other"){
      setShowRaceText(true)
    }else{
      setShowRaceText(false)
    }
  }
  const handleChangeRaceText = (e:any)=>{
    setRaceText(e.target.value)
  }
  const handleChangeHousingOther = (e:any)=>{
    formik.setFieldValue('housingtype', e.target.value)
    if(e.target.value == "Other"){
      setShowHousingText(true)
    }else{
      setShowHousingText(false)
    }
  }
  const handleChangeHousingText = (e:any)=>{
    setHousingText(e.target.value)
  }
  return (
    <><form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up as Participant</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>Step 1 - Participant's Particulars</div>
      </div>
      {/* end::Heading */}
      {(loading && xdataRef.current != null)  && <LoadingAnimation/>}
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {!isRetrieved ? <NoCompleted/> : <Completed/>}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Full Name</label>
        <input
          placeholder='Full name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('fullname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.fullname && formik.errors.fullname,
            },
            {
              'is-valid': formik.touched.fullname && !formik.errors.fullname,
            }
          )} />
        {formik.touched.fullname && formik.errors.fullname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.fullname}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>NRIC</label>
        <input
          placeholder='NRIC'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('nric')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.nric && formik.errors.nric,
            },
            {
              'is-valid': formik.touched.nric && !formik.errors.nric,
            }
          )} />
        {formik.touched.nric && formik.errors.nric && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.nric}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Gender</label>
        <select
            {...formik.getFieldProps('gender')}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.gender && formik.errors.gender,
            },
            {
                'is-valid': formik.touched.gender && !formik.errors.gender,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="male">
            {" "}
            Male
            </option>
            <option value="female">
            {" "}
            Female
            </option>
        </select>
        {formik.touched.gender && formik.errors.gender && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.gender}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Form group Phone */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Participant's Citizenship / Residential Status</label>
        <select
            {...formik.getFieldProps('residential')}
            onChange={(e) => handleChangeCitizenOther(e)}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.residential && formik.errors.residential,
            },
            {
                'is-valid': formik.touched.residential && !formik.errors.residential,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="Singapore Citizen">
            {" "}
            Singapore Citizen
            </option>
            <option value="Permanent Resident (PR)">
            {" "}
            Permanent Resident (PR)
            </option>
            <option value="Dependant's Pass">
            {" "}
            Dependant's Pass
            </option>
            <option value="Other">
            {" "}
            Other
            </option>
        </select>
        {showCitizenText && <input type='text' className='form-control bg-transparent mt-5' defaultValue={citizenText} onChange={(e) => handleChangeCitizenText(e)}/>}
        {formik.touched.residential && formik.errors.residential && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.residential}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Date of Birth</label>
        <input
          placeholder='Date of Birth'
          type='date'
          autoComplete='off'
          {...formik.getFieldProps('dob')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.dob && formik.errors.dob,
            },
            {
              'is-valid': formik.touched.dob && !formik.errors.dob,
            }
          )} />
        {formik.touched.dob && formik.errors.dob && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.dob}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Race</label>
        <select
            {...formik.getFieldProps('race')}
            onChange={(e) => handleChangeRaceOther(e)}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.race && formik.errors.race,
            },
            {
                'is-valid': formik.touched.race && !formik.errors.race,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="Chinese">
            {" "}
            Chinese
            </option>
            <option value="Malay">
            {" "}
            Malay
            </option>
            <option value="Indian">
            {" "}
            Indian
            </option>
            <option value="Other">
            {" "}
            Other
            </option>
        </select>
        {showRaceText && <input type='text' className='form-control bg-transparent mt-5' onChange={(e) => handleChangeRaceText(e)} defaultValue={raceText}/>}
        {formik.touched.race && formik.errors.race && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.race}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Mobile Number</label>
        <input
          placeholder='Mobile Number'
          type='number'
          autoComplete='off'
          {...formik.getFieldProps('mobilenumber')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.mobilenumber && formik.errors.mobilenumber,
            },
            {
              'is-valid': formik.touched.mobilenumber && !formik.errors.mobilenumber,
            }
          )} />
        {formik.touched.mobilenumber && formik.errors.mobilenumber && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.mobilenumber}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Home Address</label>
        <input
          placeholder='Home Address'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('address')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.address && formik.errors.address,
            },
            {
              'is-valid': formik.touched.address && !formik.errors.address,
            }
          )} />
        {formik.touched.address && formik.errors.address && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.address}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Postal Code</label>
        <input
          placeholder='Postal Code'
          type='number'
          autoComplete='off'
          {...formik.getFieldProps('postalcode')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.postalcode && formik.errors.postalcode,
            },
            {
              'is-valid': formik.touched.postalcode && !formik.errors.postalcode,
            }
          )} />
        {formik.touched.postalcode && formik.errors.postalcode && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.postalcode}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>What is your family's total household income?<br/>(Sum of all working adults' income in the house)</label>
        <input
          placeholder='Income'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('income')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.income && formik.errors.income,
            },
            {
              'is-valid': formik.touched.income && !formik.errors.income,
            }
          )} />
        {formik.touched.income && formik.errors.income && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.income}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>No. of Household Members</label>
        <select
            {...formik.getFieldProps('household')}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.household && formik.errors.household,
            },
            {
                'is-valid': formik.touched.household && !formik.errors.household,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="1">
            {" "}
            1
            </option>
            <option value="2">
            {" "}
            2
            </option>
            <option value="3">
            {" "}
            3
            </option>
            <option value="4">
            {" "}
            4
            </option>
            <option value="5">
            {" "}
            5
            </option>
            <option value="6">
            {" "}
            6
            </option>
            <option value="7">
            {" "}
            7
            </option>
            <option value="8">
            {" "}
            8
            </option>
            <option value="9">
            {" "}
            9
            </option>
            <option value="10">
            {" "}
            10
            </option>
            <option value=">10">
            {" "}
            More than 10
            </option>
        </select>
        {formik.touched.household && formik.errors.household && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.household}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Type of Housing</label>
        <select
            {...formik.getFieldProps('housingtype')}
            onChange={(e) => handleChangeHousingOther(e)}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.housingtype && formik.errors.housingtype,
            },
            {
                'is-valid': formik.touched.housingtype && !formik.errors.housingtype,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="2-room HDB (Rental)">
            {" "}
            2-room HDB (Rental)
            </option>
            <option value="2-room flexi HDB">
            {" "}
            2-room flexi HDB
            </option>
            <option value="3-room HDB">
            {" "}
            3-room HDB
            </option>
            <option value="4-room HDB">
            {" "}
            4-room HDB
            </option>
            <option value="5-room HDB">
            {" "}
            5-room HDB
            </option>
            <option value="Executive HDB">
            {" "}
            Executive HDB
            </option>
            <option value="Condominium">
            {" "}
            Condominium
            </option>
            <option value="Landed Property">
            {" "}
            Landed Property
            </option>
            <option value="Other">
            {" "}
            Other
            </option>
        </select>
        {showHousingText && <input type='text' className='form-control bg-transparent mt-5' onChange={(e) => handleChangeHousingText(e)} defaultValue={housingText}/>}
        {formik.touched.housingtype && formik.errors.housingtype && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.housingtype}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Do you live in a rental flat?</label>
        <select
            {...formik.getFieldProps('isrentalflat')}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.isrentalflat && formik.errors.isrentalflat,
            },
            {
                'is-valid': formik.touched.isrentalflat && !formik.errors.isrentalflat,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="Yes">
            {" "}
            Yes
            </option>
            <option value="No">
            {" "}
            No
            </option>
        </select>
        {formik.touched.isrentalflat && formik.errors.isrentalflat && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.isrentalflat}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Financial Assistance (Participant / Family)</label>
        <Select
        isMulti
        isSearchable
        options={optionfa}
        className="basic-multi-select"
        classNamePrefix="select"
        closeMenuOnSelect={true}
        components={animatedComponents}
        value={faList}
        defaultValue= {faList}      //{[{value:"MOE", label:"MOE"},{value:"Not applicable", label:"Not applicable"}]}
        onChange={(e) => handleFaSelect(e)}
        />
        {showFaText && <input type='text' className='form-control bg-transparent mt-5' onChange={(e) => handleChangeFaText(e)} defaultValue={faText}/>}
      </div>
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Participant's Medical Conditions (e.g. asthma, eczema, anxiety disorder) or<br/>Allergies (e.g. peanuts, aspirin). If none put "NA"</label>
        <input
          placeholder='Medical Conditions'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('medcons')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.medcons && formik.errors.medcons,
            },
            {
              'is-valid': formik.touched.medcons && !formik.errors.medcons,
            }
          )} />
        {formik.touched.medcons && formik.errors.medcons && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.medcons}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Participant's Learning Needs e.g. Dyslexia, ADHD, ASD  (if none put "NA")</label>
        <input
          placeholder='Learning Needs'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('learningneeds')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.learningneeds && formik.errors.learningneeds,
            },
            {
              'is-valid': formik.touched.learningneeds && !formik.errors.learningneeds,
            }
          )} />
        {formik.touched.learningneeds && formik.errors.learningneeds && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.learningneeds}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Current Education Level</label>
        <select
            {...formik.getFieldProps('edulevel')}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.edulevel && formik.errors.edulevel,
            },
            {
                'is-valid': formik.touched.edulevel && !formik.errors.edulevel,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="Pre-School">
            {" "}
            Pre-School
            </option>
            <option value="Primary School">
            {" "}
            Primary School
            </option>
            <option value="Secondary School">
            {" "}
            Secondary School
            </option>
            <option value="ITE / Poly / JC / Private Institution / University">
            {" "}
            ITE / Poly / JC / Private Institution / University
            </option>
            <option value="Not Schooling (13 years & older)">
            {" "}
            Not Schooling (13 years & older)
            </option>
        </select>
        {formik.touched.edulevel && formik.errors.edulevel && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.edulevel}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Programmes Enrolled (tick all that apply)</label>
        <Select
        isMulti
        isSearchable
        options={masterProgList}
        //value={tasekProCat}
        className="basic-multi-select"
        classNamePrefix="select"
        closeMenuOnSelect={true}
        components={animatedComponents}
        value={progList}
        defaultValue={progList}
        onChange={(e) => handleProgSelect(e)}
        />
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Does the participant have any close relatives or family members who are<br/>currently incarcerated, or have been in the past?</label>
        <select
            {...formik.getFieldProps('options1')}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.options1 && formik.errors.options1,
            },
            {
                'is-valid': formik.touched.options1 && !formik.errors.options1,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="Yes">
            {" "}
            Yes
            </option>
            <option value="No">
            {" "}
            No
            </option>
        </select>
        {formik.touched.options1 && formik.errors.options1 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.options1}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Has the participant ever faced disciplinary action in school?
        ?</label>
        <select
            {...formik.getFieldProps('options2')}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.options2 && formik.errors.options2,
            },
            {
                'is-valid': formik.touched.options2 && !formik.errors.options2,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="Yes">
            {" "}
            Yes
            </option>
            <option value="No">
            {" "}
            No
            </option>
        </select>
        {formik.touched.options2 && formik.errors.options2 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.options2}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Has the participant ever been under investigation by the Police?</label>
        <select
            {...formik.getFieldProps('options3')}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.options3 && formik.errors.options3,
            },
            {
                'is-valid': formik.touched.options3 && !formik.errors.options3,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="Yes">
            {" "}
            Yes
            </option>
            <option value="No">
            {" "}
            No
            </option>
        </select>
        {formik.touched.options3 && formik.errors.options3 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.options3}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Are the participant's parents currently divorced, separated, or have either of them<br/>passed away?</label>
        <select
            {...formik.getFieldProps('options4')}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.options4 && formik.errors.options4,
            },
            {
                'is-valid': formik.touched.options4 && !formik.errors.options4,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="Yes">
            {" "}
            Yes
            </option>
            <option value="No">
            {" "}
            No
            </option>
        </select>
        {formik.touched.options4 && formik.errors.options4 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.options4}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Is the participant or participant's family supported by a social worker or a family coach?</label>
        <select
            {...formik.getFieldProps('options5')}
            className={clsx(
            'form-control bg-transparent',
            {
                'is-invalid': formik.touched.options5 && formik.errors.options5,
            },
            {
                'is-valid': formik.touched.options5 && !formik.errors.options5,
            }
            )}
        >
            <option value="" label="Please select...">
            Please select{" "}
            </option>
            <option value="Yes">
            {" "}
            Yes
            </option>
            <option value="No">
            {" "}
            No
            </option>
        </select>
        {formik.touched.options5 && formik.errors.options5 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.options5}</span>
            </div>
          </div>
        )}
      </div>
      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting}
        >
          {!loading && <span className='indicator-label'>Save and Next</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form></>
  )
}

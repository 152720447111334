import React, { useEffect, useMemo, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {UpdateContent,SearchContent} from '../../modules/auth/core/_requests';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Form, Modal } from 'react-bootstrap';
import $ from 'jquery';
import {useAuth} from '../../../app/modules/auth'
type Props = {
	section5datax: any[],
	hasContentEdit: boolean,
  }
const SectionFive: React.FC<Props> = ({section5datax,hasContentEdit})=>{
	const [section5title, setSection5title] = useState("")
	const [section5titleid, setSection5titleid] = useState("")
	const [section5titlesec, setSection5titlesec] = useState("")

	const [section5subtitle, setSection5subtitle] = useState("")
	const [section5subtitleid, setSection5subtitleid] = useState("")
	const [section5subtitlesec, setSection5subtitlesec] = useState("")
	const [modalShow, setModalShow] = useState(false);
	const [contentId, setContentId] = useState(0);
	const [contentSection, setContentSection] = useState("");
	const [contentDesc, setContentDesc] = useState("");
	const Swal = require('sweetalert2')
	const {currentUser} = useAuth()
	const [section5data, setSection5data] = useState<any[]>(section5datax);

	const fetchContents = async () => {
		const {data: res} = await SearchContent("section5")
		setSection5data(res);
	  };

	const modules = useMemo(
		() => ({
		  toolbar: {
			container: [
			  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
			  [{ header: [1, 2, 3, 4, 5, 6, false] }], 
			  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
			  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
			  ['link', 'image'], 
			  [{ color: [] }, { background: [] }, { align: [] }],
			  ['clean'],
			],
		  },
		}),
		[],
	  );
	  const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
		'code',
		'color',
		'background',
		'code-block',
		'align',
	  ];
	const AddContentModal = (props:any) => {
		const [quillvalue, setQuillvalue] = useState('');
		console.log("halo :" + props.section)
		const handleQuillChange = (value:any) => {
		  console.log(value);
		  setQuillvalue(value)
		};
		const handleUpdate = async () => {
		  try{
			$("button#btnUpdate").html("Please wait...")
			//alert($("#ctId").val());
			let ctIdx=Number($("#ctId").val()) as number;
			let ctSection=($("#ctSection").val()) as string;
			let ctContent=($("#ctContent").val()) as string;
			//alert(ctSection)
			const {data: res} = await UpdateContent(
			  ctIdx,
			  ctSection,
			  quillvalue,
			  currentUser?.usrId,
			)
			if(res.status == "success"){
			  Swal.fire({
				title: "Success!",
				text: "Content is updated succesfully",
				icon: "success",
				timer: 2000,
				showConfirmButton: false
			  })
			  fetchContents()
			}else{
			  Swal.fire({
				title: "Error!",
				text: "Content is failed to update",
				icon: "error",
				timer: 2000,
				showConfirmButton: false
			  })     
			}
			setModalShow(false)
			$("button#btnUpdate").html("Update")
		  } catch (error) {
			Swal.fire({
			  title: "Error!",
			  text: error,
			  icon: "error",
			  timer: 2000,
			  showConfirmButton: false
			})
			$("button#btnUpdate").html("Update")  
		  }
		}
		return (
		  <Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
		  >
			<Modal.Header closeButton>
			  <Modal.Title id="contained-modal-title-vcenter">
				Edit Content
			  </Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  <Form>
				  <input type="hidden" defaultValue={props.id} id="ctId"/>
				  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
					<Form.Label>Section</Form.Label>
					{props.section != "" ?
					  <Form.Control
						defaultValue={props.section}
						type="text"
						placeholder=""
						id="ctSection"
						autoFocus
						readOnly
					  />
					  :
					  <Form.Control
					  type="text"
					  placeholder=""
					  id="ctSection"
					  autoFocus
					/>
					}
				  </Form.Group>
				  <Form.Group
					className="mb-3"
					controlId="exampleForm.ControlTextarea1"
				  >
					<Form.Label>Content</Form.Label>
					<ReactQuill theme="snow" modules={modules} formats={formats} defaultValue={contentDesc} id="ctQuill" onChange={handleQuillChange}/>
				  </Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="primary" id="btnUpdate" onClick={handleUpdate}>Update</Button>
			  <Button variant="secondary" onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		  </Modal>
		);
	  }
	  useEffect(() => {
		for (let i = 0; i < section5data.length; i++) {
			let res = section5data[i].ctSection
			if(res == "Section5Title"){
				setSection5title(section5data[i].ctContent)
				setSection5titleid(section5data[i].ctId)
				setSection5titlesec(section5data[i].ctSection)
			}
			if(res == "Section5SubTitle"){
				setSection5subtitle(section5data[i].ctContent)
				setSection5subtitleid(section5data[i].ctId)
				setSection5subtitlesec(section5data[i].ctSection)
			}
		}
	  }, [section5data]);
	  const handleContentEdit = (idx:number, ctSection:string,ctDesc:string) => {
		setModalShow(true)
		setContentId(idx)
		setContentSection(ctSection)
		setContentDesc(ctDesc)
	  };
    return (
        <>
        <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-12'>
          <div className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13" style={{background: "linear-gradient(90deg, #20AA3E 0%, #03A588 100%"}}>
						<div className="my-2 me-5">
							<div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">{<span dangerouslySetInnerHTML={{ __html: section5title }}></span>} {hasContentEdit && <a className="btn btn-warning btn-sm" href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section5titleid),section5titlesec,section5title)}>Edit</a>}</div>
							<div className="fs-6 fs-lg-5 text-white fw-semibold opacity-75">{<span dangerouslySetInnerHTML={{ __html: section5subtitle + "" }}></span>} {hasContentEdit && <a className="btn btn-warning btn-sm" href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section5subtitleid),section5subtitlesec,section5subtitle)}>Edit</a>}</div>
						</div>
						{ !currentUser && <a href="/auth/retrieveregdata" className="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2">Registration Page</a> }
					</div>
          </div>
        </div>    
		<AddContentModal
        id={contentId}
        section={contentSection}
        desc={contentDesc}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />  
        </>
    )
}
export default SectionFive;
import {useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {GetStep1,GetStep2,getUserByToken, register,GetContentBySection} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import LoadingAnimation from '../../../LoadingAnimation'

  var initialValues = {
    kidstart: '',
    schoolname: '',
    schoolyear: '',
    ccaname: '',
    coursename: '',
    employstatus: '',
    jobtitle: '',
  }
  export function RegStep2() {
    const [xdata, setXdata] = useState([]);
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    const [modalShow, setModalShow] = useState(false);
    const [showSyText, setShowSyText] = useState(false)
    const [syText, setSyText] = useState("")
    const Swal = require('sweetalert2')
    let xdataRef = useRef<any>()
    let xdataRef1 = useRef<any>()
    let edulevel=""

    const fetchData= async () => {
      setLoading(true);
  
      const {data: xcontent} = await GetStep2(localStorage.getItem("regToken"));
      const {data: xcontent1} = await GetStep1(localStorage.getItem("regToken"));
  
      xdataRef.current=xcontent;
      xdataRef1.current=xcontent1;
      if(xdataRef.current != null){
        console.log(xdataRef.current.schoolyear)
        //if(edulevel == "ITE / Poly / JC / Private Institution / University"){
          //console.log(xdataRef.current.schoolyear)
          if(String(xdataRef.current.schoolyear).includes("Other")){
            setSyText(String(xdataRef.current.schoolyear).split("|")[1])
            setShowSyText(true)
          }
        //}
      }
      setLoading(false);
    };
    useEffect(() => {
      fetchData()
    }, []);
    if(xdataRef.current != null && xdataRef1.current != null){
      edulevel=xdataRef1.current.edulevel
      if(edulevel == "Pre-School"){
        initialValues = {
          kidstart: xdataRef.current.kidstart !== "undefined" ? xdataRef.current.kidstart : "",
          schoolname: xdataRef.current.schoolname !== "undefined" ? xdataRef.current.schoolname : "",
          schoolyear: xdataRef.current.schoolyear !== "undefined" ? xdataRef.current.schoolyear : "",
          ccaname: "",
          coursename: "",
          employstatus: "",
          jobtitle: "",
        }
      }else if(edulevel == "Primary School"){
        initialValues = {
          kidstart: "",
          schoolname: xdataRef.current.schoolname !== "undefined" ? xdataRef.current.schoolname : "",
          schoolyear: xdataRef.current.schoolyear !== "undefined" ? xdataRef.current.schoolyear : "",
          ccaname: xdataRef.current.ccaname !== "undefined" ? xdataRef.current.ccaname : "",
          coursename: "",
          employstatus: "",
          jobtitle: "",
        }
      }else if(edulevel == "Secondary School"){
        initialValues = {
          kidstart: "",
          schoolname: xdataRef.current.schoolname !== "undefined" ? xdataRef.current.schoolname : "",
          schoolyear: xdataRef.current.schoolyear !== "undefined" ? xdataRef.current.schoolyear : "",
          ccaname: xdataRef.current.ccaname !== "undefined" ? xdataRef.current.ccaname : "",
          coursename: "",
          employstatus: "",
          jobtitle: "",
        }
      }else if(edulevel == "ITE / Poly / JC / Private Institution / University"){
        //alert(1)
        initialValues = {
          kidstart: "",
          schoolname: xdataRef.current.schoolname !== "undefined" ? xdataRef.current.schoolname : "",
          schoolyear: xdataRef.current.schoolyear !== "undefined" ? (String(xdataRef.current.schoolyear).includes("Other") ? "Other" : xdataRef.current.schoolyear) : "",
          ccaname: xdataRef.current.ccaname !== "undefined" ? xdataRef.current.ccaname : "",
          coursename: xdataRef.current.coursename !== "undefined" ? xdataRef.current.coursename : "",
          employstatus: "",
          jobtitle: "",
        }
      }else{
        initialValues = {
          kidstart: "",
          schoolname: "",
          schoolyear: "",
          ccaname: "",
          coursename: "",
          employstatus: xdataRef.current.employstatus !== "undefined" ? xdataRef.current.employstatus : "",
          jobtitle: xdataRef.current.jobtitle !== "undefined" ? xdataRef.current.jobtitle : "",
        }
      }
    }
    var registrationSchema=Yup.object().shape({})
    if(edulevel == "Pre-School"){
      registrationSchema = Yup.object().shape({
        kidstart: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('You must select kidstart program'),
        schoolname: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('School Name is required'),
        schoolyear: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('School year is required'),
      })
    }else if(edulevel == "Primary School"){
      registrationSchema = Yup.object().shape({
        schoolname: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('School Name is required'),
        ccaname: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('CCA is required'),
        schoolyear: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('School year is required'),
      })
    }else if(edulevel == "Secondary School"){
      registrationSchema = Yup.object().shape({
        schoolname: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('School Name is required'),
        ccaname: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('CCA is required'),
        schoolyear: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('School year is required'),
      })
    }else if(edulevel == "ITE / Poly / JC / Private Institution / University"){
      registrationSchema = Yup.object().shape({
        schoolname: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('School Name is required'),
        ccaname: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('CCA is required'),
        schoolyear: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('School year is required'),
        coursename: Yup.string()
        .min(2, 'Minimum 2 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Course Name is required'),
      })
    }else{
      registrationSchema = Yup.object().shape({
        employstatus: Yup.string()
          .min(2, 'Minimum 2 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Employment Status is required'),
        jobtitle: Yup.string()
          .min(2, 'Minimum 2 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Job Title is required'),
      })
    }
    const handleChangeSyOther = (e:any)=>{
      formik.setFieldValue('schoolyear', e.target.value)
      if(e.target.value == "Other"){
        setShowSyText(true)
      }else{
        setShowSyText(false)
      }
    }
    const handleChangeSyText = (e:any)=>{
      setSyText(e.target.value)
    }
    const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
        setLoading(true)
        if(showSyText){
          if(syText == ""){
            Swal.fire({
              title: "Error!",
              text: "School year other is mandatory!",
              icon: "error",
              timer: 3000,
              showConfirmButton: false
            })  
            setSubmitting(false)
            setLoading(false)
            return
          }
        }
        try {
          let formData=new FormData();
          formData.append("kidstart", values.kidstart);
          formData.append("schoolname",values.schoolname);
          formData.append("schoolyear",values.schoolyear == "Other" ? "Other|" + syText : values.schoolyear);
          formData.append("ccaname", values.ccaname);
          formData.append("coursename",values.coursename);
          formData.append("employstatus",values.employstatus);
          formData.append("jobtitle",values.jobtitle);
          formData.append("token",String(localStorage.getItem("regToken")));
          await axios.post(process.env.REACT_APP_API_URL + `/SaveStep2`, formData, {})
          .then(function (res) { // then print response status
              console.warn(res.data);
              if(res.data.status == "success"){
                window.location.href = "/auth/step3"
                setLoading(false)
                setSubmitting(false)
              }else{
                Swal.fire({
                  title: "Error!",
                  text: "Your data is failed to save",
                  icon: "error",
                  timer: 3000,
                  showConfirmButton: false
                })     
                setLoading(false)
                setSubmitting(false)
              }
          })
        } catch (error) {
          console.error(error)
          Swal.fire({
            title: "Error!",
            text: error,
            icon: "error",
            timer: 2000,
            showConfirmButton: false
          })
        }
    },
    })
    return (
        <><form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {/* begin::Heading */}
          <div className='text-center mb-11'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
            {/* end::Title */}
    
            <div className='text-gray-500 fw-semibold fs-6'>Step 2 - {edulevel}</div>
          </div>
          {/* end::Heading */}
          {loading && <LoadingAnimation/>}
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
    
   
          {/* begin::Form group Email */}
          {edulevel=="Pre-School" &&
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Are you enrolled in the KidSTART programme?</label>
            <select
                {...formik.getFieldProps('kidstart')}
                className={clsx(
                'form-control bg-transparent',
                {
                    'is-invalid': formik.touched.kidstart && formik.errors.kidstart,
                },
                {
                    'is-valid': formik.touched.kidstart && !formik.errors.kidstart,
                }
                )}
            >
                <option value="" label="Please select...">
                Please select{" "}
                </option>
                <option value="Yes">
                {" "}
                Yes
                </option>
                <option value="No">
                {" "}
                No
                </option>
            </select>
            {formik.touched.kidstart && formik.errors.kidstart && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.kidstart}</span>
                </div>
              </div>
            )}
          </div>
          }
          {(edulevel=="Pre-School" || edulevel=="Primary School" || edulevel=="Secondary School" || edulevel=="ITE / Poly / JC / Private Institution / University") &&
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>School Name (if not applicable, put NA)</label>
            <input
            placeholder='School Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('schoolname')}
            className={clsx(
                'form-control bg-transparent',
                {
                'is-invalid': formik.touched.schoolname && formik.errors.schoolname,
                },
                {
                'is-valid': formik.touched.schoolname && !formik.errors.schoolname,
                }
            )} />
            {formik.touched.schoolname && formik.errors.schoolname && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.schoolname}</span>
                </div>
            </div>
            )}
        </div>
        }
        {(edulevel=="Primary School" || edulevel=="Secondary School" || edulevel=="ITE / Poly / JC / Private Institution / University") &&
        <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>CCA (if not applicable, put NA)</label>
            <input
            placeholder='CCA Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('ccaname')}
            className={clsx(
                'form-control bg-transparent',
                {
                'is-invalid': formik.touched.ccaname && formik.errors.ccaname,
                },
                {
                'is-valid': formik.touched.ccaname && !formik.errors.ccaname,
                }
            )} />
            {formik.touched.ccaname && formik.errors.ccaname && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.ccaname}</span>
                </div>
            </div>
            )}
        </div>
        }
        {edulevel=="ITE / Poly / JC / Private Institution / University" &&
        <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Course Name</label>
            <input
            placeholder='Course Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('coursename')}
            className={clsx(
                'form-control bg-transparent',
                {
                'is-invalid': formik.touched.coursename && formik.errors.coursename,
                },
                {
                'is-valid': formik.touched.coursename && !formik.errors.coursename,
                }
            )} />
            {formik.touched.coursename && formik.errors.coursename && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.coursename}</span>
                </div>
            </div>
            )}
        </div>
        }
        {edulevel=="Not Schooling (13 years & older)" &&
        <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Job Title</label>
            <input
            placeholder='Job Title'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('jobtitle')}
            className={clsx(
                'form-control bg-transparent',
                {
                'is-invalid': formik.touched.jobtitle && formik.errors.jobtitle,
                },
                {
                'is-valid': formik.touched.jobtitle && !formik.errors.jobtitle,
                }
            )} />
            {formik.touched.jobtitle && formik.errors.jobtitle && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.jobtitle}</span>
                </div>
            </div>
            )}
        </div>
        }
            {/* -------------- employment status -------------- */}
            {edulevel=="Not Schooling (13 years & older)" &&
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Employment Status</label>
            <select
                {...formik.getFieldProps('employstatus')}
                className={clsx(
                'form-control bg-transparent',
                {
                    'is-invalid': formik.touched.employstatus && formik.errors.employstatus,
                },
                {
                    'is-valid': formik.touched.employstatus && !formik.errors.employstatus,
                }
                )}
            >
                <option value="" label="Please select...">
                Please select{" "}
                </option>
                <option value="Full Time">
                {" "}
                Full Time
                </option>
                <option value="Part Time">
                {" "}
                Part Time
                </option>
                <option value="Unemployed">
                {" "}
                Unemployed
                </option>
                <option value="Out-of-School Programme">
                {" "}
                Out-of-School Programme
                </option>
                <option value="Other">
                {" "}
                Other
                </option>
            </select>
            {formik.touched.employstatus && formik.errors.employstatus && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.employstatus}</span>
                </div>
              </div>
            )}
          </div>
            }
          {/* ------------------------------------------ */}
            {/* -------------- pre-school -------------- */}
            {edulevel=="Pre-School" &&
            <div className='fv-row mb-8'>
              <label className='form-label fw-bolder text-dark fs-6'>School Year</label>
              <select
                  {...formik.getFieldProps('schoolyear')}
                  className={clsx(
                  'form-control bg-transparent',
                  {
                      'is-invalid': formik.touched.schoolyear && formik.errors.schoolyear,
                  },
                  {
                      'is-valid': formik.touched.schoolyear && !formik.errors.schoolyear,
                  }
                  )}
              >
                  <option value="" label="Please select...">
                  Please select{" "}
                  </option>
                  <option value="Infantcare">
                  {" "}
                  Infantcare
                  </option>
                  <option value="Playgroup">
                  {" "}
                  Playgroup
                  </option>
                  <option value="Nursery 1">
                  {" "}
                  Nursery 1
                  </option>
                  <option value="Nursery 2">
                  {" "}
                  Nursery 2
                  </option>
                  <option value="Kindergarten 1">
                  {" "}
                  Kindergarten 1
                  </option>
                  <option value="Kindergarten 2">
                  {" "}
                  Kindergarten 2
                  </option>
                  <option value="Not Applicable">
                  {" "}
                  Not Applicable
                  </option>
              </select>
              {formik.touched.schoolyear && formik.errors.schoolyear && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.schoolyear}</span>
                  </div>
                </div>
              )}
            </div>
            }
          {/* ------------------------------------------ */}
          {/* -------------- primary -------------- */}
          {edulevel=="Primary School" &&
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>School Year</label>
            <select
                {...formik.getFieldProps('schoolyear')}
                className={clsx(
                'form-control bg-transparent',
                {
                    'is-invalid': formik.touched.schoolyear && formik.errors.schoolyear,
                },
                {
                    'is-valid': formik.touched.schoolyear && !formik.errors.schoolyear,
                }
                )}
            >
                <option value="" label="Please select...">
                Please select{" "}
                </option>
                <option value="Primary 1">
                {" "}
                Primary 1
                </option>
                <option value="Primary 2">
                {" "}
                Primary 2
                </option>
                <option value="Primary 3">
                {" "}
                Primary 3
                </option>
                <option value="Primary 4">
                {" "}
                Primary 4
                </option>
                <option value="Primary 5">
                {" "}
                Primary 5
                </option>
                <option value="Primary 6">
                {" "}
                Primary 6
                </option>
            </select>
            {formik.touched.schoolyear && formik.errors.schoolyear && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.schoolyear}</span>
                </div>
              </div>
            )}
          </div>
          }
          {/* ------------------------------------------ */}
          {/* -------------- secondary -------------- */}
          {edulevel=="Secondary School" &&
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>School Year</label>
            <select
                {...formik.getFieldProps('schoolyear')}
                className={clsx(
                'form-control bg-transparent',
                {
                    'is-invalid': formik.touched.schoolyear && formik.errors.schoolyear,
                },
                {
                    'is-valid': formik.touched.schoolyear && !formik.errors.schoolyear,
                }
                )}
            >
                <option value="" label="Please select...">
                Please select{" "}
                </option>
                <option value="Secondary 1">
                {" "}
                Secondary 1
                </option>
                <option value="Secondary 2">
                {" "}
                Secondary 2
                </option>
                <option value="Secondary 3">
                {" "}
                Secondary 3
                </option>
                <option value="Secondary 4">
                {" "}
                Secondary 4
                </option>
                <option value="Secondary 5">
                {" "}
                Secondary 5
                </option>
            </select>
            {formik.touched.schoolyear && formik.errors.schoolyear && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.schoolyear}</span>
                </div>
              </div>
            )}
          </div>
          }
          {/* ------------------------------------------ */}
          {/* -------------- ite -------------- */}
          {edulevel=="ITE / Poly / JC / Private Institution / University" &&
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>School Year</label>
            <select
                {...formik.getFieldProps('schoolyear')}
                onChange={(e) => handleChangeSyOther(e)}
                className={clsx(
                'form-control bg-transparent',
                {
                    'is-invalid': formik.touched.schoolyear && formik.errors.schoolyear,
                },
                {
                    'is-valid': formik.touched.schoolyear && !formik.errors.schoolyear,
                }
                )}
            >
                <option value="" label="Please select...">
                Please select{" "}
                </option>
                <option value="Year 1">
                {" "}
                Year 1
                </option>
                <option value="Year 2">
                {" "}
                Year 2
                </option>
                <option value="Year 3">
                {" "}
                Year 3
                </option>
                <option value="Year 4">
                {" "}
                Year 4
                </option>
                <option value="Other">
                {" "}
                Other
                </option>
            </select>
            {showSyText && <input type='text' className='form-control bg-transparent mt-5' defaultValue={syText} onChange={(e) => handleChangeSyText(e)}/>}
            {formik.touched.schoolyear && formik.errors.schoolyear && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.schoolyear}</span>
                </div>
              </div>
            )}
          </div>
          }
          {/* ------------------------------------------ */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
            >
              {!loading && <span className='indicator-label'>Save and Next</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/regas/participant'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
              >
                Previous Step
              </button>
            </Link>
          </div>
          {/* end::Form group */}
        </form></>
      )
    }
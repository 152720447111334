import React, { useEffect, useMemo, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {UpdateContent,SetRegProgramme,SearchContent} from '../../modules/auth/core/_requests';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Form, Modal } from 'react-bootstrap';
import $ from 'jquery';
import {useAuth} from '../../../app/modules/auth'
import dayjs from "dayjs";
type Props = {
	section3datax: any[],
	randomprogramme: any[],
	hasContentEdit: boolean,
  }
const SectionThree: React.FC<Props> = ({section3datax,randomprogramme,hasContentEdit})=>{
	const [section3title, setSection3title] = useState("")
	const [section3titleid, setSection3titleid] = useState("")
	const [section3titlesec, setSection3titlesec] = useState("")

	const [section3subtitle, setSection3subtitle] = useState("")
	const [section3subtitleid, setSection3subtitleid] = useState("")
	const [section3subtitlesec, setSection3subtitlesec] = useState("")

	const [proidx, setProidx] = useState("")
	const [procatx, setProcatx] = useState("")
	const [protitlex, setproTitlex] = useState("")
	const [prodescx, setproDescx] = useState("")
	const [prodaysx, setproDaysx] = useState("")
	const [prostartdatex, setproStartDatex] = useState("")
	const [proenddatex, setProEndDatex] = useState("")
	const [protimestartx, setProTimeStartx] = useState("")
	const [protimeendx, setProTimeEnd] = useState("")
	const [profeex, setProfeex] = useState("")
	const [prosetforx, setProSetForx] = useState("")
	const [proorgnamex, setProOrgNamex] = useState("")
	const [prohoursx, setProHoursx] = useState("")
	const [propointx, setProPointx] = useState("")
	const [tasekFreqId, setTasekFreqId] = useState(0)
	const [tasekFreqMonday, setTasekFreqMonday] = useState(false)
    const [tasekFreqThursday, setTasekFreqThursday] = useState(false)
    const [tasekFreqWednesday, setTasekFreqWednesday] = useState(false)
    const [tasekFreqTuesday, setTasekFreqTuesday] = useState(false)
    const [tasekFreqFriday, setTasekFreqFriday] = useState(false)
    const [tasekFreqSaturday, setTasekFreqSaturday] = useState(false)
    const [tasekFreqSunday, setTasekFreqSunday] = useState(false)
    const [tasekFreqStartTime, setTasekFreqStartTime] = useState("")
    const [tasekFreqEndTime, setTasekFreqEndTime] = useState("")

	const [proidx2, setProidx2] = useState("")
	const [procatx2, setProcatx2] = useState("")
	const [protitlex2, setproTitlex2] = useState("")
	const [prodescx2, setproDescx2] = useState("")
	const [prodaysx2, setproDaysx2] = useState("")
	const [prostartdatex2, setproStartDatex2] = useState("")
	const [proenddatex2, setProEndDatex2] = useState("")
	const [protimestartx2, setProTimeStartx2] = useState("")
	const [protimeendx2, setProTimeEnd2] = useState("")
	const [profeex2, setProfeex2] = useState("")	
	const [prosetforx2, setProSetForx2] = useState("")
	const [proorgnamex2, setProOrgNamex2] = useState("")
	const [prohoursx2, setProHoursx2] = useState("")
	const [propointx2, setProPointx2] = useState("")
	const [tasekFreqId2, setTasekFreqId2] = useState(0)
	const [tasekFreqMonday2, setTasekFreqMonday2] = useState(false)
    const [tasekFreqThursday2, setTasekFreqThursday2] = useState(false)
    const [tasekFreqWednesday2, setTasekFreqWednesday2] = useState(false)
    const [tasekFreqTuesday2, setTasekFreqTuesday2] = useState(false)
    const [tasekFreqFriday2, setTasekFreqFriday2] = useState(false)
    const [tasekFreqSaturday2, setTasekFreqSaturday2] = useState(false)
    const [tasekFreqSunday2, setTasekFreqSunday2] = useState(false)
    const [tasekFreqStartTime2, setTasekFreqStartTime2] = useState("")
    const [tasekFreqEndTime2, setTasekFreqEndTime2] = useState("")


	const [proidx3, setProidx3] = useState("")
	const [procatx3, setProcatx3] = useState("")
	const [protitlex3, setproTitlex3] = useState("")
	const [prodescx3, setproDescx3] = useState("")
	const [prodaysx3, setproDaysx3] = useState("")
	const [prostartdatex3, setproStartDatex3] = useState("")
	const [proenddatex3, setProEndDatex3] = useState("")
	const [protimestartx3, setProTimeStartx3] = useState("")
	const [protimeendx3, setProTimeEnd3] = useState("")
	const [profeex3, setProfeex3] = useState("")
	const [prosetforx3, setProSetForx3] = useState("")
	const [proorgnamex3, setProOrgNamex3] = useState("")
	const [prohoursx3, setProHoursx3] = useState("")
	const [propointx3, setProPointx3] = useState("")
	const [tasekFreqId3, setTasekFreqId3] = useState(0)
	const [tasekFreqMonday3, setTasekFreqMonday3] = useState(false)
    const [tasekFreqThursday3, setTasekFreqThursday3] = useState(false)
    const [tasekFreqWednesday3, setTasekFreqWednesday3] = useState(false)
    const [tasekFreqTuesday3, setTasekFreqTuesday3] = useState(false)
    const [tasekFreqFriday3, setTasekFreqFriday3] = useState(false)
    const [tasekFreqSaturday3, setTasekFreqSaturday3] = useState(false)
    const [tasekFreqSunday3, setTasekFreqSunday3] = useState(false)
    const [tasekFreqStartTime3, setTasekFreqStartTime3] = useState("")
    const [tasekFreqEndTime3, setTasekFreqEndTime3] = useState("")

	const [modalShow, setModalShow] = useState(false);
	const [contentId, setContentId] = useState(0);
	const [contentSection, setContentSection] = useState("");
	const [contentDesc, setContentDesc] = useState("");
	const Swal = require('sweetalert2')
	const {currentUser} = useAuth()
	const [section3data, setSection3data] = useState<any[]>(section3datax);

	console.log(randomprogramme.length > 0 && randomprogramme)
	const fetchContents = async () => {
		const {data: res} = await SearchContent("section3")
		setSection3data(res);
	  };
	const modules = useMemo(
		() => ({
		  toolbar: {
			container: [
			  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
			  [{ header: [1, 2, 3, 4, 5, 6, false] }], 
			  ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
			  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
			  ['link', 'image'], 
			  [{ color: [] }, { background: [] }, { align: [] }],
			  ['clean'],
			],
		  },
		}),
		[],
	  );
	  const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'indent',
		'link',
		'image',
		'code',
		'color',
		'background',
		'code-block',
		'align',
	  ];
	const AddContentModal = (props:any) => {
		const [quillvalue, setQuillvalue] = useState('');
		console.log("halo :" + props.section)
		const handleQuillChange = (value:any) => {
		  console.log(value);
		  setQuillvalue(value)
		};
		const handleUpdate = async () => {
		  try{
			$("button#btnUpdate").html("Please wait...")
			//alert($("#ctId").val());
			let ctIdx=Number($("#ctId").val()) as number;
			let ctSection=($("#ctSection").val()) as string;
			let ctContent=($("#ctContent").val()) as string;
			//alert(ctSection)
			const {data: res} = await UpdateContent(
			  ctIdx,
			  ctSection,
			  quillvalue,
			  currentUser?.usrId,
			)
			if(res.status == "success"){
			  Swal.fire({
				title: "Success!",
				text: "Content is updated succesfully",
				icon: "success",
				timer: 2000,
				showConfirmButton: false
			  })
			  fetchContents()
			}else{
			  Swal.fire({
				title: "Error!",
				text: "Content is failed to update",
				icon: "error",
				timer: 2000,
				showConfirmButton: false
			  })     
			}
			setModalShow(false)
			$("button#btnUpdate").html("Update")
		  } catch (error) {
			Swal.fire({
			  title: "Error!",
			  text: error,
			  icon: "error",
			  timer: 2000,
			  showConfirmButton: false
			})
			$("button#btnUpdate").html("Update")  
		  }
		}
		return (
		  <Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
		  >
			<Modal.Header closeButton>
			  <Modal.Title id="contained-modal-title-vcenter">
				Edit Content
			  </Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  <Form>
				  <input type="hidden" defaultValue={props.id} id="ctId"/>
				  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
					<Form.Label>Section</Form.Label>
					{props.section != "" ?
					  <Form.Control
						defaultValue={props.section}
						type="text"
						placeholder=""
						id="ctSection"
						autoFocus
						readOnly
					  />
					  :
					  <Form.Control
					  type="text"
					  placeholder=""
					  id="ctSection"
					  autoFocus
					/>
					}
				  </Form.Group>
				  <Form.Group
					className="mb-3"
					controlId="exampleForm.ControlTextarea1"
				  >
					<Form.Label>Content</Form.Label>
					<ReactQuill theme="snow" modules={modules} formats={formats} defaultValue={contentDesc} id="ctQuill" onChange={handleQuillChange}/>
				  </Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
			  <Button variant="primary" id="btnUpdate" onClick={handleUpdate}>Update</Button>
			  <Button variant="secondary" onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		  </Modal>
		);
	  }
	  useEffect(() => {
		for (let i = 0; i < section3data.length; i++) {
			let res = section3data[i].ctSection
			if(res == "Section3Title"){
				setSection3title(section3data[i].ctContent)
				setSection3titleid(section3data[i].ctId)
				setSection3titlesec(section3data[i].ctSection)
			}
			if(res == "Section3SubTitle"){
				setSection3subtitle(section3data[i].ctContent)
				setSection3subtitleid(section3data[i].ctId)
				setSection3subtitlesec(section3data[i].ctSection)
			}
		}
		if(randomprogramme.length > 0){
			setProidx(randomprogramme[0].proId)
			setProcatx(randomprogramme[0].proCat)
			setproTitlex(randomprogramme[0].proTitle)
			setproDescx(randomprogramme[0].proDesc)
			setproDaysx(randomprogramme[0].proDays)
			setproStartDatex(randomprogramme[0].proStartDate)
			setProEndDatex(randomprogramme[0].proEndDate)
			setProTimeStartx(randomprogramme[0].proTimeStart)
			setProTimeEnd(randomprogramme[0].proTimeEnd)
			setProfeex(randomprogramme[0].proFee)	
			setProSetForx(randomprogramme[0].proSetFor)
			setProOrgNamex(randomprogramme[0].proOrgName)	
			setProHoursx(randomprogramme[0].proHours)
			setProPointx(randomprogramme[0].proPoint)	
			setTasekFreqId(randomprogramme[0].proFreq.id);
			setTasekFreqMonday(randomprogramme[0].proFreq.isMonday);
			setTasekFreqThursday(randomprogramme[0].proFreq.isThursday);
			setTasekFreqWednesday(randomprogramme[0].proFreq.isWednesday);
			setTasekFreqTuesday(randomprogramme[0].proFreq.isTuesday);
			setTasekFreqFriday(randomprogramme[0].proFreq.isFriday);
			setTasekFreqSaturday(randomprogramme[0].proFreq.isSaturday);
			setTasekFreqSunday(randomprogramme[0].proFreq.isSunday);
			setTasekFreqStartTime(randomprogramme[0].proFreq.startTime);
			setTasekFreqEndTime(randomprogramme[0].proFreq.endTime)
		}
		if(randomprogramme.length > 1){
			setProidx2(randomprogramme[1].proId)
			setProcatx2(randomprogramme[1].proCat)
			setproTitlex2(randomprogramme[1].proTitle)
			setproDescx2(randomprogramme[1].proDesc)
			setproDaysx2(randomprogramme[1].proDays)
			setproStartDatex2(randomprogramme[1].proStartDate)
			setProEndDatex2(randomprogramme[1].proEndDate)
			setProTimeStartx2(randomprogramme[1].proTimeStart)
			setProTimeEnd2(randomprogramme[1].proTimeEnd)
			setProfeex2(randomprogramme[1].proFee)	
			setProSetForx2(randomprogramme[1].proSetFor)
			setProOrgNamex2(randomprogramme[1].proOrgName)	
			setProHoursx2(randomprogramme[1].proHours)
			setProPointx2(randomprogramme[1].proPoint)
			setTasekFreqId2(randomprogramme[1].proFreq.id);
			setTasekFreqMonday2(randomprogramme[1].proFreq.isMonday);
			setTasekFreqThursday2(randomprogramme[1].proFreq.isThursday);
			setTasekFreqWednesday2(randomprogramme[1].proFreq.isWednesday);
			setTasekFreqTuesday2(randomprogramme[1].proFreq.isTuesday);
			setTasekFreqFriday2(randomprogramme[1].proFreq.isFriday);
			setTasekFreqSaturday2(randomprogramme[1].proFreq.isSaturday);
			setTasekFreqSunday2(randomprogramme[1].proFreq.isSunday);
			setTasekFreqStartTime2(randomprogramme[1].proFreq.startTime);
			setTasekFreqEndTime2(randomprogramme[1].proFreq.endTime)
		}
		if(randomprogramme.length > 2){
			setProidx3(randomprogramme[2].proId)
			setProcatx3(randomprogramme[2].proCat)
			setproTitlex3(randomprogramme[2].proTitle)
			setproDescx3(randomprogramme[2].proDesc)
			setproDaysx3(randomprogramme[2].proDays)
			setproStartDatex3(randomprogramme[2].proStartDate)
			setProEndDatex3(randomprogramme[2].proEndDate)
			setProTimeStartx3(randomprogramme[2].proTimeStart)
			setProTimeEnd3(randomprogramme[2].proTimeEnd)
			setProfeex3(randomprogramme[2].proFee)	
			setProSetForx3(randomprogramme[2].proSetFor)
			setProOrgNamex3(randomprogramme[2].proOrgName)	
			setProHoursx3(randomprogramme[2].proHours)
			setProPointx3(randomprogramme[2].proPoint)
			setTasekFreqId3(randomprogramme[2].proFreq.id);
			setTasekFreqMonday3(randomprogramme[2].proFreq.isMonday);
			setTasekFreqThursday3(randomprogramme[2].proFreq.isThursday);
			setTasekFreqWednesday3(randomprogramme[2].proFreq.isWednesday);
			setTasekFreqTuesday3(randomprogramme[2].proFreq.isTuesday);
			setTasekFreqFriday3(randomprogramme[2].proFreq.isFriday);
			setTasekFreqSaturday3(randomprogramme[2].proFreq.isSaturday);
			setTasekFreqSunday3(randomprogramme[2].proFreq.isSunday);
			setTasekFreqStartTime3(randomprogramme[2].proFreq.startTime);
			setTasekFreqEndTime3(randomprogramme[2].proFreq.endTime)
		}
	  }, [section3data]);
	  const handleContentEdit = (idx:number, ctSection:string,ctDesc:string) => {
		setModalShow(true)
		setContentId(idx)
		setContentSection(ctSection)
		setContentDesc(ctDesc)
	  };
	  const handleRegClick = async (e:any,proId:any,proSetFor:any,proCat:any,proTitle:any,proDesc:any,proDays:any,proStartDate:any,proEndDate:any,proTimeStart:any,proTimeEnd:any,proFee:any,proOrgName:any,proHours:any,proPoint:any) => {
		e.preventDefault();
		const {data: res} = await SetRegProgramme(
			currentUser?.usrId,
			proId,
			proSetFor,
			proCat,
			proTitle,
			proDesc,
			proDays,
			proTimeStart,
			proTimeEnd,
			proFee,
			proOrgName,
			proStartDate,
			proEndDate,
			proHours,
			proPoint
		)
		window.location.replace(toAbsoluteUrl("/TermAndConditions"));
	  };
	  // prog 1
	  let htmlperms=""
	  let htmlperms2=""
	  let htmlperms3=""
	  const days = [];
	  const days2 = [];
	  const days3 = [];
	  if(tasekFreqId == 0){
		htmlperms = "<span class=\"badge py-3 px-4 fs-7 badge-light-danger mt-2 mb-2 \">N/A</span>";
	  }else{
		if(tasekFreqMonday){
		  days.push("Monday")
		}
		if(tasekFreqThursday){
		  days.push("Thursday")
		}
		if(tasekFreqWednesday){
		  days.push("Wednesday")
		}
		if(tasekFreqTuesday){
		  days.push("Tuesday")
		}
		if(tasekFreqFriday){
		  days.push("Friday")
		}
		if(tasekFreqSaturday){
		  days.push("Saturday")
		}
		if(tasekFreqSunday){
		  days.push("Sunday")
		}
		let i = 0;
		while (i < days.length) {
			console.log(days[i]);
			htmlperms = htmlperms + "<span class=\"badge py-3 px-4 fs-7 badge-light-success mt-2 mb-2 \">" + days[i] + "</span>&nbsp;";
			i++;
		}
		//return (<> {<div dangerouslySetInnerHTML={{ __html: htmlperms }}></div>}</>);
	  }
	  if(tasekFreqId2 == 0){
		htmlperms2 = "<span class=\"badge py-3 px-4 fs-7 badge-light-danger mt-2 mb-2 \">N/A</span>";
	  }else{
		if(tasekFreqMonday2){
		  days2.push("Monday")
		}
		if(tasekFreqThursday2){
		  days2.push("Thursday")
		}
		if(tasekFreqWednesday2){
		  days2.push("Wednesday")
		}
		if(tasekFreqTuesday2){
		  days2.push("Tuesday")
		}
		if(tasekFreqFriday2){
		  days2.push("Friday")
		}
		if(tasekFreqSaturday2){
		  days2.push("Saturday")
		}
		if(tasekFreqSunday2){
		  days2.push("Sunday")
		}
		let i = 0;
		while (i < days.length) {
			console.log(days[i]);
			htmlperms2 = htmlperms + "<span class=\"badge py-3 px-4 fs-7 badge-light-success mt-2 mb-2 \">" + days2[i] + "</span>&nbsp;";
			i++;
		}
		//return (<> {<div dangerouslySetInnerHTML={{ __html: htmlperms }}></div>}</>);
	  }
	  if(tasekFreqId3 == 0){
		htmlperms3 = "<span class=\"badge py-3 px-4 fs-7 badge-light-danger mt-2 mb-2 \">N/A</span>";
	  }else{
		if(tasekFreqMonday3){
		  days3.push("Monday")
		}
		if(tasekFreqThursday3){
		  days3.push("Thursday")
		}
		if(tasekFreqWednesday3){
		  days3.push("Wednesday")
		}
		if(tasekFreqTuesday3){
		  days3.push("Tuesday")
		}
		if(tasekFreqFriday3){
		  days3.push("Friday")
		}
		if(tasekFreqSaturday3){
		  days3.push("Saturday")
		}
		if(tasekFreqSunday3){
		  days3.push("Sunday")
		}
		let i = 0;
		while (i < days.length) {
			console.log(days[i]);
			htmlperms3 = htmlperms + "<span class=\"badge py-3 px-4 fs-7 badge-light-success mt-2 mb-2 \">" + days3[i] + "</span>&nbsp;";
			i++;
		}
		//return (<> {<div dangerouslySetInnerHTML={{ __html: htmlperms }}></div>}</>);
	  }
	function capitalizeFirstLetter(xstr:string) {
		return xstr.charAt(0).toUpperCase() + xstr.slice(1);
	}
	var xsub_arr1=procatx.split(";")
	var xsub_arr2=procatx2.split(";")
	var xsub_arr3=procatx3.split(";")
	var xprosubtitle1=xsub_arr1.map((item:any) => item.length <= 3 ? item.toUpperCase() : capitalizeFirstLetter(item)).join(' | ')
	var xprosubtitle2=xsub_arr2.map((item:any) => item.length <= 3 ? item.toUpperCase() : capitalizeFirstLetter(item)).join(' | ')
	var xprosubtitle3=xsub_arr3.map((item:any) => item.length <= 3 ? item.toUpperCase() : capitalizeFirstLetter(item)).join(' | ')
    return (
        <>
          <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-12'>
          <div className="py-20 landing-dark-bg">
          <div  className="container">
						<div  className="d-flex flex-column container pt-lg-5">
							<div  className="mb-13 text-center">
								<h1  className="fs-2hx fw-bold text-white mb-5" id="pricing" data-kt-scroll-offset="{default: 100, lg: 150}">{<span dangerouslySetInnerHTML={{ __html: section3title }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section3titleid),section3titlesec,section3title)}>[ Edit ]</a>}</h1>
								<div  className="text-gray-600 fw-semibold fs-5">{<span dangerouslySetInnerHTML={{ __html: section3subtitle + "" }}></span>} {hasContentEdit && <a href="javascript:;" style={{fontSize:"16px",fontWeight:"normal"}} onClick={() => handleContentEdit(Number(section3subtitleid),section3subtitlesec,section3subtitle)}>[ Edit ]</a>}</div>
							</div>
							<div  className="text-center" id="kt_pricing">
								<div  className="row g-10">
									<div  className="col-xl-4">
										<div  className="d-flex h-100 align-items-center">
											<div  className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10"> 
												<div  className="mb-7 text-center">
													<h1  className="text-dark mb-5 fw-boldest fs-2">{capitalizeFirstLetter(protitlex)}</h1>
													<div  className="text-gray-400 fw-semibold mb-5">{xsub_arr1.length > 0 ? capitalizeFirstLetter(xsub_arr1[0]) : capitalizeFirstLetter(procatx)}</div>
												</div>
												<div  className="w-100 mb-10">	
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-gray-800 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-warning mt-2 mb-2">Date :</span> {dayjs(prostartdatex).format('DD/MM/YYYY')} - {dayjs(proenddatex).format('DD/MM/YYYY')}</span>
														<span  className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
													</div>																							 
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-gray-800 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-warning mt-2 mb-2">Desc :</span> {prodescx}</span>
														<span  className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
													</div>
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-gray-800 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-warning mt-2 mb-2">Day :</span> {<span dangerouslySetInnerHTML={{ __html: htmlperms }}></span>}</span>
														<span  className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
													</div>													
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-gray-800 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-warning mt-2 mb-2">Fee :</span> {profeex == "0" ? "Free" : profeex}</span>
														<span  className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
													</div>																										 
												</div>
												<div className="row">
													<div className="col-md-6">
														<a href="javascript:;" onClick={(e) => handleRegClick(e,proidx,prosetforx,procatx,protitlex,prodescx,prodaysx,prostartdatex,proenddatex,protimestartx,protimeendx,profeex,proorgnamex,prohoursx,propointx)} className="btn btn-primary">Enrol</a>
													</div>
													<div className="col-md-6">
														<a href={"/Programme/" + (xsub_arr1.length > 0 ? xsub_arr1[0] : procatx)}  className="btn btn-danger">More..</a>
													</div>
												</div>
											</div>
											 
										</div>
									</div>
									<div  className="col-xl-4">
										<div  className="d-flex h-100 align-items-center">
											 
											<div  className="w-100 d-flex flex-column flex-center rounded-3 bg-primary py-20 px-10">
												 
												<div  className="mb-7 text-center">
													 
													<h1  className="text-white mb-5 fw-boldest fs-2"> {capitalizeFirstLetter(protitlex2)}</h1>
													<div  className="text-white opacity-75 fw-semibold mb-5">{xsub_arr2.length > 0 ? capitalizeFirstLetter(xsub_arr2[0]) : capitalizeFirstLetter(procatx2)}</div>
													</div>
												<div  className="w-100 mb-10">
													 
													 
												<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-white opacity-75 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-primary mt-2 mb-2">Date :</span> {dayjs(prostartdatex2).format('DD/MM/YYYY')} - {dayjs(proenddatex2).format('DD/MM/YYYY')}</span>
														 
														<span  className="svg-icon svg-icon-1 svg-icon-white">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
														 
													</div>													 
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-white opacity-75 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-primary mt-2 mb-2">Desc :</span> {prodescx2}</span>
														 
														<span  className="svg-icon svg-icon-1 svg-icon-white">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
														 
													</div>
													 
													 
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-white opacity-75 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-primary mt-2 mb-2">Day :</span> {<span dangerouslySetInnerHTML={{ __html: htmlperms2 }}></span>}</span>
														 
														<span  className="svg-icon svg-icon-1 svg-icon-white">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
														 
													</div>									 
													 
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-white opacity-75 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-primary mt-2 mb-2">Fee :</span> {profeex2 == "0" ? "Free" : profeex2 }</span>
														 
														<span  className="svg-icon svg-icon-1 svg-icon-white">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
														 
													</div>
													 
												
													 
												</div>
												<div className="row">
													<div className="col-md-6">
														<a href="javascript:;" onClick={(e) => handleRegClick(e,proidx2,prosetforx2,procatx2,protitlex2,prodescx2,prodaysx2,prostartdatex2,proenddatex2,protimestartx2,protimeendx2,profeex2,proorgnamex2,prohoursx2,propointx2)} className="btn btn-color-primary btn-active-light-primary btn-light">Enrol</a>
													</div>
													<div className="col-md-6">
														<a href={"/Programme/" + (xsub_arr2.length > 0 ? xsub_arr2[0] : procatx2)}  className="btn btn-danger">More..</a>
													</div>
												</div>
											 
											</div>
											 
										</div>
									</div>
									 
									 
									<div  className="col-xl-4">
										<div  className="d-flex h-100 align-items-center">
											<div  className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
												<div  className="mb-7 text-center">
													<h1  className="text-dark mb-5 fw-boldest fs-2"> {capitalizeFirstLetter(protitlex3)}</h1>
													<div  className="text-gray-400 fw-semibold mb-5">{xsub_arr3.length > 0 ? capitalizeFirstLetter(xsub_arr3[0]) : capitalizeFirstLetter(procatx3)}</div>	 
												</div>
												 
												<div  className="w-100 mb-10">	
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-gray-800 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-warning mt-2 mb-2">Date :</span> {dayjs(prostartdatex3).format('DD/MM/YYYY')} - {dayjs(proenddatex3).format('DD/MM/YYYY')}</span>
														<span  className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
													</div>											 
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-gray-800 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-warning mt-2 mb-2">Desc:</span> {prodescx3}</span>
														<span  className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
													</div>
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-gray-800 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-warning mt-2 mb-2">Day : </span> {<span dangerouslySetInnerHTML={{ __html: htmlperms3 }}></span>}</span>
														<span  className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
													</div>													
													<div  className="d-flex flex-stack mb-5">
														<span  className="fw-semibold fs-6 text-gray-800 text-start pe-3"><span className="badge py-3 px-4 fs-7 badge-light-warning mt-2 mb-2">Fee :</span> {profeex3 == "0" ? "Free" : profeex3}</span>
														<span  className="svg-icon svg-icon-1 svg-icon-success">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
																<path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"></path>
															</svg>
														</span>
													</div>																										 
												</div>
												<div className="row">
													<div className="col-md-6">
														<a href="javascript:;" onClick={(e) => handleRegClick(e,proidx3,prosetforx3,procatx3,protitlex3,prodescx3,prodaysx3,prostartdatex3,proenddatex3,protimestartx3,protimeendx3,profeex3,proorgnamex3,prohoursx3,propointx3)} className="btn btn-primary">Enrol</a>
													</div>
													<div className="col-md-6">
														<a href={"/Programme/" + (xsub_arr3.length > 0 ? xsub_arr3[0] : procatx3)} className="btn btn-danger">More..</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>				 
						</div>
					</div>
          </div>
          </div>
          </div>    
		  <AddContentModal
        id={contentId}
        section={contentSection}
        desc={contentDesc}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />  
        </>
    )
}
export default SectionThree;
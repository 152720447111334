/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { RegStep1 } from './components/RegStep1'
import { RegStep2 } from './components/RegStep2'
import { RegStep3 } from './components/RegStep3'
import { RegStep4 } from './components/RegStep4'
import { RetrieveRegData } from './components/RetrieveRegData'

const AuthLayout2 = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Wrapper */}
        <div className='col-md-12 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <div className='row'>
            <div className='col-md-6'>
              <Outlet />
            </div>
            <div className="col-md-6">
            <div className="card h-md-100" dir="ltr">
                <div className="card-body d-flex flex-column">
                    <div className="mb-2">
                        <h3 className='mt-5 text-center'>Tasek Academy and Social Services Limited</h3>
                        <div className="py-10 text-center">
                            <img alt='Volunteering' src={toAbsoluteUrl('/media/images/4934035.jpg')} style={{maxWidth:"100%"}}/>
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}

const AuthPage2 = () => (
  <Routes>
    <Route element={<AuthLayout2 />}>
      <Route path='login' element={<Login />} />
      <Route path='retrieveregdata' element={<RetrieveRegData />} />
      <Route path='regas/parent' element={<Registration />} />
      <Route path='regas/volunteer' element={<Registration />} />
      <Route path='regas/participant' element={<RegStep1 />} />
      <Route path='step2' element={<RegStep2 />} />
      <Route path='step3' element={<RegStep3 />} />
      <Route path='step4' element={<RegStep4 />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage2}

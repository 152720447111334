/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import { BrowserView, MobileView } from 'react-device-detect';

type Props = {
  className?: string
  bgColor?: string
  title?: string
  title2?: string
}
const SectionHeader = ({
  className,
  bgColor = '#13263c',
  title = 'Volunteer and Participant Management System',
  title2 = 'Tasek Academy and Social Services Limited',
}: Props) => {
  const {currentUser} = useAuth()
  return (
    <><BrowserView>
      <div
        className={clsx('card h-175px bgi-no-repeat bgi-size-contain', className)}
        style={{
          backgroundColor: bgColor,
          backgroundPosition: 'right',
          backgroundImage: `url("${toAbsoluteUrl('/media/svg/misc/taieri.svg')}")`,
        }}
      >
        <div className='card-body d-flex flex-column justify-content-between'>
          <h2 className='fw-bold mb-5 text-white'>
            {title} <br /> <span className='fs-5'>{title2}</span>{' '}
          </h2>

          <div className='m-0'>
            {currentUser ?
              (
                <h5 className='text-white'>Welcome {currentUser.usrFirstname} {currentUser.usrLastname}...</h5>
              )
              :
              (<a
                href='/auth/retrieveregdata'
                className='btn btn-danger fw-semibold px-6 py-3'
              >
                <i className="ki-duotone ki-notepad-edit fs-2"><i className="path1"></i><i className="path2"></i></i>
                Click here to Register
              </a>)}
          </div>
        </div>
      </div>
    </BrowserView>
    <MobileView>
        <div
          className={clsx('card h-175px bgi-no-repeat bgi-size-contain', className)}
          style={{
            backgroundColor: bgColor,
            backgroundPosition: 'right',
          }}
        >
          <div className='card-body d-flex flex-column justify-content-between'>
            <h2 className='fw-bold mb-5 text-white'>
              {title} <br /> <span className='fs-5'>{title2}</span>{' '}
            </h2>

            <div className='m-0'>
              {currentUser ?
                (
                  <h5 className='text-white'>Welcome {currentUser.usrFirstname} {currentUser.usrLastname}...</h5>
                )
                :
                (<a
                  href='/auth/registration'
                  className='btn btn-danger fw-semibold px-6 py-3'
                >
                  <i className="ki-duotone ki-notepad-edit fs-2"><i className="path1"></i><i className="path2"></i></i>
                  Click here to Register
                </a>)}
            </div>
          </div>
        </div>
      </MobileView></>
  )
}

export {SectionHeader}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2';
import {Buffer} from 'buffer';

const loginSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Full name is required'),
  phonenumber: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Phone number is required'),
})

const initialValues = {
  fullname: '',
  phonenumber: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function RetrieveRegData() {
  const [loading, setLoading] = useState(false)
  const [showRegAs, setShowRegAs] = useState(false)
  const {saveAuth, setCurrentUser,currentUser} = useAuth()
  const Swal = require('sweetalert2')

  useEffect(() => {
    localStorage.clear()
  }, []);
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        localStorage.setItem("regFullname",values.fullname)
        localStorage.setItem("regPhoneNumber",values.phonenumber)
        let encodedToken = Buffer.from(values.fullname.toLowerCase() + "-" + values.phonenumber).toString("base64");
        localStorage.setItem("regToken",encodedToken)
        window.location.href = "/auth/regas/participant";
        //console.log(currentUser)
      } catch (error) {
        console.error(error)  
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  return (
    <>
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign Up As Participant</h1>
          <div className='text-gray-500 fw-semibold fs-6'>Retrieve Registration Data Draft From Server</div>
        </div>
        {/* begin::Heading */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) /* : (
          <><div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>
              Administrator : <strong>admin@demo.com</strong> & password <strong>demo</strong><br />
              <hr />
              Volunteer : <strong>volunteer@demo.com</strong> & password <strong>demo</strong>
            </div>
          </div></>
        ) */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Full Name</label>
          <input
            placeholder='Full Name'
            {...formik.getFieldProps('fullname')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.fullname && formik.errors.fullname },
              {
                'is-valid': formik.touched.fullname && !formik.errors.fullname,
              }
            )}
            type='text'
            autoComplete='off' />
          {formik.touched.fullname && formik.errors.fullname && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.fullname}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Phone Number</label>
          <input
            placeholder='Phone Number'
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('phonenumber')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.phonenumber && formik.errors.phonenumber,
              },
              {
                'is-valid': formik.touched.phonenumber && !formik.errors.phonenumber,
              }
            )} />
          {formik.touched.phonenumber && formik.errors.phonenumber && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phonenumber}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Retrieve Registration Data</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/regas/participant'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Skip this Step
          </button>
        </Link>
        </div>
        {/* end::Action */}
      </form></>
  )
}

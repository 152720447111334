import {useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {GetStep4,getUserByToken, register,GetContentBySection,SaveAllStep} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import LoadingAnimation from '../../../LoadingAnimation'

var initialValues = {
    consentperson: '',
    consentcontactnumber: '',
    acceptTerms: false,
  }
  const registrationSchema = Yup.object().shape({
      consentperson: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Emergency Contact Name is required'),
      consentcontactnumber: Yup.string()
      .min(6, 'Minimum 6 symbols')
      .max(6, 'Maximum 6 symbols')
      .required('Emergency Contact relationship is required'),
      acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
  })
  export function RegStep4() {
    const [xdata, setXdata] = useState([]);
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    const [modalShow, setModalShow] = useState(false);
    const Swal = require('sweetalert2')
    let xdataRef = useRef<any>()

    const fetchDataStep4= async () => {
      setLoading(true);
  
      const {data: xcontent} = await GetStep4(localStorage.getItem("regToken"));
  
      xdataRef.current=xcontent;
      setLoading(false);
    };
    const fetchData = async () => {
      setLoading(true);
  
      const {data: xcontent } = await GetContentBySection("Tnc");
  
      console.log(xcontent)
      setXdata(xcontent[0]);
      setLoading(false);
    };
    useEffect(() => {
      fetchData()
      fetchDataStep4()
    }, [])

    if(xdataRef.current != null){
      initialValues = {
        consentperson: xdataRef.current.consentperson,
        consentcontactnumber: xdataRef.current.consentcontactnumber,
        acceptTerms: false,
      }
    }
    const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
        setLoading(true)
        try {
          let formData=new FormData();
          formData.append("consentperson", values.consentperson);
          formData.append("consentcontactnumber",values.consentcontactnumber);
          formData.append("token",String(localStorage.getItem("regToken")));
          await axios.post(process.env.REACT_APP_API_URL + `/SaveStep4`, formData, {})
          .then(function (res) { // then print response status
              console.warn(res.data);
              if(res.data.status == "success"){
                //window.location.href = "/auth/step4"
                setLoading(false)
                setSubmitting(false)
                Swal.fire({
                  title: "Success!",
                  html: "Your data has been saved as draft, to save your enrolment on our system please submit your data!",
                  icon: "success",
                  timer: 5000,
                  showConfirmButton: false
                })
              }else{
                Swal.fire({
                  title: "Error!",
                  text: "Your data is failed to save",
                  icon: "error",
                  timer: 3000,
                  showConfirmButton: false
                })     
                setLoading(false)
                setSubmitting(false)
              }
          })
        } catch (error) {
          console.error(error)
          Swal.fire({
            title: "Error!",
            text: error,
            icon: "error",
            timer: 2000,
            showConfirmButton: false
          })
        }
    },
    })
    const SubmitYourData=()=>{
      Swal.fire({
        title: "Are you sure?",
        text: "You want to submit your data?",
        icon: "question",
        showConfirmButton:true,
        confirmButtonText: 'Yes, submit it!',
        showCancelButton:true
      })
      .then (async (willDelete: any) => {
        if (willDelete.isConfirmed) {
          const {data: res} = await SaveAllStep(localStorage.getItem("regToken"))
          if(res.status == "success"){
            Swal.fire({
              title: "Success!",
              html: "Your data has been submitted succesfully, we will proceed your details immediately!",
              icon: "success",
              timer: 3000,
              showConfirmButton: false
            }).then(() => {
              localStorage.clear();
              window.location.href = "/auth/login";
            })  
          }else{
            Swal.fire({
              title: "Error!",
              text: "Your data is failed to submit",
              icon: "error",
              timer: 2000,
              showConfirmButton: false
            })     
          }
        }
      }); 
    }
    const AddTncModal = (props:any) => {
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Terms and Condition
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>{<div dangerouslySetInnerHTML={{ __html: props.xdata.ctContent }}></div>}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
    }
    const handleTncClick = (e:any) => {
      e.preventDefault();
      setModalShow(true)
    };
    return (
        <><form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {/* begin::Heading */}
          <div className='text-center mb-11'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
            {/* end::Title */}
    
            <div className='text-gray-500 fw-semibold fs-6'>Step 4 - Consent</div>
          </div>
          {/* end::Heading */}
          {loading && <LoadingAnimation/>}
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
    
   
        <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Full Name of Person Giving Consent</label>
            <input
            placeholder='Full Name of Person Giving Consent'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('consentperson')}
            className={clsx(
                'form-control bg-transparent',
                {
                'is-invalid': formik.touched.consentperson && formik.errors.consentperson,
                },
                {
                'is-valid': formik.touched.consentperson && !formik.errors.consentperson,
                }
            )} />
            {formik.touched.consentperson && formik.errors.consentperson && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.consentperson}</span>
                </div>
            </div>
            )}
        </div>
        <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Contact Number of Person Giving Consent</label>
            <input
            placeholder='Contact Number of Person Giving Consent'
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('consentcontactnumber')}
            className={clsx(
                'form-control bg-transparent',
                {
                'is-invalid': formik.touched.consentcontactnumber && formik.errors.consentcontactnumber,
                },
                {
                'is-valid': formik.touched.consentcontactnumber && !formik.errors.consentcontactnumber,
                }
            )} />
            {formik.touched.consentcontactnumber && formik.errors.consentcontactnumber && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.consentcontactnumber}</span>
                </div>
            </div>
            )}
        </div>
        <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')} />
          <span>
            I Accept the{' '}
            <a
              href='#'
              onClick={(e) => handleTncClick(e)}
              className='ms-1 link-primary'
            >
              Terms & Condition
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label'>Save</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-success w-100 mb-5'
                onClick={SubmitYourData}
              >
                Submit
              </button>
            <Link to='/auth/step3'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
              >
                Previous Step
              </button>
            </Link>
          </div>
          {/* end::Form group */}
        </form>
        <AddTncModal
        xdata={xdata}
        show={modalShow}
        onHide={() => setModalShow(false)} />
        </>
      )
    }
import {useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {GetStep3,getUserByToken, register,GetContentBySection} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import LoadingAnimation from '../../../LoadingAnimation'

var initialValues = {
    emercontactname: '',
    emercontactrel: '',
    emercontactnumb: '',
  }
  const registrationSchema = Yup.object().shape({
    emercontactname: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Emergency Contact Name is required'),
    emercontactrel: Yup.string()
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Emergency Contact relationship is required'),
    emercontactnumb: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(6, 'MaxSimum 6 symbols')
    .required('Emergency contact number is required'),
  })
  export function RegStep3() {
    const [xdata, setXdata] = useState([]);
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    const [modalShow, setModalShow] = useState(false);
    const Swal = require('sweetalert2')
    let xdataRef = useRef<any>()

    const fetchData= async () => {
      setLoading(true);
  
      const {data: xcontent} = await GetStep3(localStorage.getItem("regToken"));
  
      xdataRef.current=xcontent;
      setLoading(false);
    };
    useEffect(() => {
      fetchData()
    }, []);
    if(xdataRef.current != null){
      initialValues = {
        emercontactname: xdataRef.current.emercontactname,
        emercontactrel: xdataRef.current.emercontactrel,
        emercontactnumb: xdataRef.current.emercontactnumb,
      }
    }
    const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
        setLoading(true)
        try {
          let formData=new FormData();
          formData.append("emercontactname", values.emercontactname);
          formData.append("emercontactrel",values.emercontactrel);
          formData.append("emercontactnumb",values.emercontactnumb);
          formData.append("token",String(localStorage.getItem("regToken")));
          await axios.post(process.env.REACT_APP_API_URL + `/SaveStep3`, formData, {})
          .then(function (res) { // then print response status
              console.warn(res.data);
              if(res.data.status == "success"){
                window.location.href = "/auth/step4"
                setLoading(false)
                setSubmitting(false)
              }else{
                Swal.fire({
                  title: "Error!",
                  text: "Your data is failed to save",
                  icon: "error",
                  timer: 3000,
                  showConfirmButton: false
                })     
                setLoading(false)
                setSubmitting(false)
              }
          })
        } catch (error) {
          console.error(error)
          Swal.fire({
            title: "Error!",
            text: error,
            icon: "error",
            timer: 2000,
            showConfirmButton: false
          })
        }
    },
    })
    return (
        <><form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {/* begin::Heading */}
          <div className='text-center mb-11'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
            {/* end::Title */}
    
            <div className='text-gray-500 fw-semibold fs-6'>Step 3 - Emergency Contact's Particulars</div>
          </div>
          {/* end::Heading */}
    
          {loading && <LoadingAnimation/>}
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
    
   
        <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Emergency Contact's Full Name</label>
            <input
            placeholder='Emergency Contact Full Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('emercontactname')}
            className={clsx(
                'form-control bg-transparent',
                {
                'is-invalid': formik.touched.emercontactname && formik.errors.emercontactname,
                },
                {
                'is-valid': formik.touched.emercontactname && !formik.errors.emercontactname,
                }
            )} />
            {formik.touched.emercontactname && formik.errors.emercontactname && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.emercontactname}</span>
                </div>
            </div>
            )}
        </div>
        <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Emergency Contact's Relationship to Participant</label>
            <select
                {...formik.getFieldProps('emercontactrel')}
                className={clsx(
                'form-control bg-transparent',
                {
                    'is-invalid': formik.touched.emercontactrel && formik.errors.emercontactrel,
                },
                {
                    'is-valid': formik.touched.emercontactrel && !formik.errors.emercontactrel,
                }
                )}
            >
                <option value="" label="Please select...">
                Please select{" "}
                </option>
                <option value="Mother">
                {" "}
                Mother
                </option>
                <option value="Father">
                {" "}
                Father
                </option>
                <option value="Grandmother">
                {" "}
                Grandmother
                </option>
                <option value="Grandfather">
                {" "}
                Grandfather
                </option>
                <option value="Sibling (Above 21 years old)">
                {" "}
                Sibling (Above 21 years old)
                </option>
                <option value="Other">
                {" "}
                Other
                </option>
            </select>
            {formik.touched.emercontactrel && formik.errors.emercontactrel && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.emercontactrel}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Emergency Contact Number</label>
            <input
            placeholder='Emergency Contact Number'
            type='number'
            autoComplete='off'
            {...formik.getFieldProps('emercontactnumb')}
            className={clsx(
                'form-control bg-transparent',
                {
                'is-invalid': formik.touched.emercontactnumb && formik.errors.emercontactnumb,
                },
                {
                'is-valid': formik.touched.emercontactnumb && !formik.errors.emercontactnumb,
                }
            )} />
            {formik.touched.emercontactnumb && formik.errors.emercontactnumb && (
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert'>{formik.errors.emercontactnumb}</span>
                </div>
            </div>
            )}
        </div>
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
            >
              {!loading && <span className='indicator-label'>Save and Next</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/step2'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
              >
                Previous Step
              </button>
            </Link>
          </div>
          {/* end::Form group */}
        </form></>
      )
    }